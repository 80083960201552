<template>
  <div class="faq">
    <section>
      <div class="container fluid">
        <div class="row aic">
          <div class="col-auto">
            <ul class="breadcumb">
              <li>
                <router-link :to="{ name: 'profile-index' }">
                  {{ localize('breadcrumb')['home'] }}
                </router-link>
              </li>
              <img src="../../assets/profile_assets/chevron-right.svg" alt="" />
              <li>
                <router-link class="active" :to="{ name: 'profile.faq' }">
                  {{ localize('breadcrumb')['whatIsPaycoin'] }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-auto">
            <router-link :to="{ name: 'profile-index' }" class="btn-back">
              {{ localize('button')['back'] }}
            </router-link>
          </div>
        </div>
        <h2>Paycoin</h2>
        <div class="row">
          <div class="col-12">
            <ul>
              <li
                v-for="(n, i) in faq"
                :key="i"
                ref="faq_list"
                class="faq_list"
              >
                <div
                  class="faq_head"
                  @dblclick.prevent
                  @mousedown.prevent
                >
                  <div class="icon" :class="{ active: n.active }">
                    <img src="../../assets/icons/faqArrow.svg" alt="" />
                  </div>
                  <h3>{{ n.heading }}</h3>
                </div>
                <div class="faq_body" :class="{ active: n.active }">
                  <p>
                    {{ n.title }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <router-link :to="{ name: 'profile-index' }" class="p-button d-block-sm">
          {{ localize('button')['back'] }}
        </router-link>
      </div>
    </section>
  </div>
</template>
<script>
import localize from '../../filters/localize.filter';
import $ from 'jquery';

export default {
  name: 'questPaycoin',
  data: () => ({ faq: localize('pay-coin-info') }),
  mounted() {
    $('.faq_head').click(function () {

      $(this).next().slideToggle();
      $('.faq_body').not($(this).next()).slideUp();

      $(this).find('.icon img').toggleClass('active');
    });
  },
  methods: { localize },
};
</script>
<style lang="scss" scoped>
  @import "../../assets/css/faq.scss";

  .faq {
    padding: 48px 44px;
    min-height: calc(100vh - 96px);
    background: $grey;
    width: 100%;

    header {
      text-align: center;
      padding: 56px 0;
      background: $grey url("../../assets/icons/Vector.svg") no-repeat calc(50% - 120px) 37px;

      h1 {
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        color: $black;
      }
    }

    section {
      padding: 0px;

      .faq_body p {
        max-width: 800px;
      }
    }

    h2 {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $black;
      margin-top: 16px;
      margin-bottom: 32px;
    }
  }

  .aic {
    align-items: center;
    justify-content: space-between;
  }

  .btn-back {
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 8px;
    width: auto;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border: none;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: $main-light;
    }
  }

  .breadcumb {
    display: flex;
    align-items: center;

    li {
      margin-bottom: 0px !important;
    }

    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: $black;
      opacity: 0.3;

      &.active {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $main;
        opacity: 1;
      }
    }
  }

  .d-block-sm {
    display: none;
  }

  @media (max-width: 575px) {
    .d-block-sm {
      display: block;
    }
    a.p-button.d-block-sm {
      cursor: pointer;
      background: $main;
      border-radius: 14px;
      width: auto;
      padding: 18px 24px;
      font-weight: 600;
      font-size: 18px;
      line-height: 111.63%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      transition: 0.2s;
      border: none;
      margin-top: 24px;

      &:hover {
        background: $main-light;
      }
    }
    .faq {
      padding: 24px 0;

      h2 {
        font-size: 20px;
        line-height: 25px;
        color: $black;
        margin-bottom: 0px;
        margin-top: 8px;
      }
    }
    .breadcumb a,
    .breadcumb a.active {
      font-size: 13px;
      line-height: 16px;
    }
    .btn-back {
      display: none;
    }
  }
</style>
